<template>
  <div>
    <h1>Contact</h1>
  </div>
</template>

<script>
export default {
  name: "Contact"
};
</script>
